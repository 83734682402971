import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Button, SearchInput, DataTable, Pager, TabList, Dialog, Input, notification, SearchBox } from 'tyb';
const { setNavBar } = iceStarkData.store.get('commonAction')?.ui;
const { changeTerminalPaging, terminalList, enableOrDisabledTerminal } = iceStarkData.store.get('commonAction')?.terminalManagement;
import TableHeaderSettingModal from '@/pages/BaseManagement/components/TableHeaderSettingModal'
const { fieldAssignEseQuery, configTableTitle } = iceStarkData.store.get('commonAction')?.fieldAssign;
import { Confirm } from 'ucode-components'
import $http from '@/utils/http'
import { DownLoadAnimate } from 'ucode-components'
import enums from '@/assets/enum.json'

// const BusinessStates = Object.keys(enums.shopBusinessState).map(key => ({
//     value: key,
//     text: enums.shopBusinessState[key].name
// }))

const StateColors = {
    'OPERATION': '#22c55e',
    'UNCLAIMED': '#f59e0b',
    'CANCEL': '#BEBEBE'
}

@connect(
    state => ({
        terminalPaging: state.terminalManagement.terminalPaging,
        loading: state.terminalManagement.loading,
        terminalData: state.terminalManagement.terminalData,
        permissionIds: state.users.permissionIds,
        eseData: state.fieldAssign.eseData,
    }),
    {
        setNavBar,
        changeTerminalPaging,
        getTerminalList: terminalList.REQUEST,
        enableOrDisabledTerminal: enableOrDisabledTerminal.REQUEST,
        handleConfigTableTitle: configTableTitle.REQUEST,
        getEseData: fieldAssignEseQuery.REQUEST,
    })
export default class TerminalManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            THSVisible: false,
            gapDay: '',
            configVisible: false,
            configLoading: false,
            animateShow: 0,
            exportLoading: false,
            businessState: '',
            name: ''
        };
        this.status = { ENABLE: '禁用', DISABLE: '启用' };
        this.title = { add: '新增门店', modify: '编辑门店' };
        this.columns = [
            {
                key: 'id',
                dataIndex: 'id',
                title: '终端ID',
            },
            {
                key: 'code',
                dataIndex: 'code',
                title: '终端编码',
            },
            // {
            //     key: 'code',
            //     dataIndex: 'code',
            //     title: '来源',
            // },
            {
                key: 'name',
                dataIndex: 'name',
                title: '终端名称',
            },
            {
                key: 'contact',
                dataIndex: 'contact',
                title: '联系人',
            },
            {
                key: 'mobile',
                dataIndex: 'mobile',
                title: '手机号',
            },
            {
                key: 'address',
                dataIndex: 'address',
                title: '地址',
            },
            {
                key: 'status',
                dataIndex: 'status',
                title: '状态',
                render: (val) => <div>{val == 'ENABLE' ? '启用' : '禁用'}</div>
            },
            {
                key: 'businessState',
                dataIndex: 'businessState',
                title: '经营状态',
                render: value => <div style={{color:StateColors[value]}}>{enums.shopBusinessState[value]?.name}</div>,
                // headerRender: col => <DataTable.FilterableColumnHeader list={BusinessStates} value={this.state.businessState ? this.state.businessState.split(',') : []} onFilter={e => {
                //     this.setState({ businessState: e.filter(v => v).join() }, () => {
                //         this.props.changeTerminalPaging({ ...this.props.terminalPaging, businessState: this.state.businessState })
                //         this.props.getTerminalList(this.props.terminalPaging)
                //     })
                // }}>
                //     {col.title}
                // </DataTable.FilterableColumnHeader>
            },
            {
                key: 'operate',
                dataIndex: 'operate',
                title: '操作',
                render: (val, record) => (
                    <div className="table-operate-btn">
                        {this.props.permissionIds.includes('data.basicData.shop.edit') && <span onClick={() => { this.handleOperate('modify', record) }}>编辑</span>}
                        {this.props.permissionIds.includes('data.basicData.shop.status') && record.businessState !== 'CANCEL' && <span onClick={() => { this.props.enableOrDisabledTerminal({ id: record.id, status: record.status == 'ENABLE' ? 'DISABLE' : 'ENABLE' }) }}>{record.status == 'ENABLE' ? '停用' : '启用'}</span>}
                        {record.businessState !== 'CANCEL' && <span onClick={() => {
                            Confirm({
                                type: 'warning',
                                title: '注销门店',
                                content: '确认注销该门店？注销后无法恢复！',
                                onOk: () => {
                                    return $http({
                                        url: `/data/portal/shops/v1/${record.id}/businessState?businessState=CANCEL`,
                                        method: 'PATCH'
                                    }).then(() => {
                                        this.props.getTerminalList(this.props.terminalPaging)
                                    })
                                }
                            })
                        }}>注销</span>}
                    </div>
                )
            },
        ]
    }

    componentDidMount() {
        this.props.getTerminalList(this.props.terminalPaging);
        this.props.getEseData({ dictDataType: "TERMINAL_SHOP" });
    }

    componentDidUpdate(preProps) {
    }

    componentWillUnmount() {
        this.props.changeTerminalPaging({})
    }

    handleOperate = (type, record = {}) => {
        switch (type) {
            case 'export':
                break;
            default:
                this.props.setNavBar(`${this.title[type]}`);
                this.props.history.push(`/databasicData/datashop/edit?${queryString.stringify({ id: record.id })}`);
                break;
        }
    }

    exportList = () => {
        this.setState({ exportLoading: true })
        $http({
            url: '/data/portal/shops/v1/excel',
            method: 'post',
            data: {
                word: this.props.terminalPaging?.code || undefined,
                name: this.state.name || undefined
            }
        }).then(() => {
            this.setState({ animateShow: this.state.animateShow + 1 })
        }).finally(() => {
            this.setState({ exportLoading: false })
        })
    }

    getColumns() {
        const columns = [...this.columns]
        const dynamicColumns = this.props.eseData.filter(it => it.titleShow).map(it => {
            return {
              key: it.code,
              dataIndex: it.code,
              title: it.name,
              render: (value, record) => {
                let data = []
                try {
                  data = JSON.parse(record.customFields)
                } catch(err) {}
                if (!data || typeof data !== 'object' || !data.length) {
                  return
                }
                const target = data.find(t => t.code === it.code)
                if (!target) {
                  return
                }
                if (it.dictType === 'PICTURE') {
                  return target.value && <img style={{width:'60px',height:'60px',objectFit:'contain'}} src={target.value} />
                }
                return <div>{target.value}</div>
              }
            }
        })
        columns.splice(columns.length - 1, 0, ...dynamicColumns)
        return columns
    }

    render() {
        const { loading, terminalData, terminalPaging, permissionIds } = this.props;
        const { content, totalElements } = terminalData;
        return (
            <React.Fragment>
                <div className="search-bar" style={{ display:'flex', alignItems:'center' }}>
                    {permissionIds.includes('data.basicData.shop.add') && <Button style={{ marginRight: 10 }} onClick={() => { this.handleOperate('add'); }}>新增终端</Button>}
                    <Button className="mr-10" onClick={() => { this.setState({ THSVisible: true }) }}>表头设置</Button>
                    {permissionIds.includes('data.basicData.shop.group') && <Button className="weak-button mr-10" onClick={() => { this.props.history.push('/databasicData/datashop/category') }}>终端分类</Button>}
                    <Button className="weak-button mr-10 export-button" onClick={this.exportList} disabled={this.state.exportLoading}>导出</Button>
                    <Button className="weak-button mr-10" onClick={() => { this.props.history.push('/databasicData/datashop/point-import') }}>批量积分导入</Button>
                    <Button className="weak-button" onClick={() => { this.setState({ configVisible: true }) }}>门店修改限制</Button>
                    <div
                        style={{
                            flexGrow: 1,
                            flexShrink: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                        <Input
                            style={{ width: 180 }}
                            placeholder="请输入终端名称搜索"
                            value={this.state.name}
                            onChange={e => {
                                this.setState({ name: e.target.value })
                            }}
                        />
                        <Input
                            style={{ width: 180 }}
                            className="ml-10"
                            placeholder="请输入终端ID/编码/第三方编码搜索"
                            value={terminalPaging.code}
                            onChange={e => {
                                this.props.changeTerminalPaging({ ...terminalPaging, code: e.target.value })
                            }}
                        />
                        <Button
                            style={{ flexShrink: 0 }}
                            className="ml-10"
                            onClick={() => {
                                this.props.getTerminalList({
                                    ...terminalPaging,
                                    name: this.state.name,
                                    page: 0
                                })
                            }}>
                            查询
                        </Button>
                    </div>
                </div>

                <DataTable
                    columns={this.getColumns()}
                    data={content}
                    rowKey="id"
                    loading={loading}
                    rowClassName={(record) => record.status == 'DISABLE' ? 'table-gray-row' : ''}
                />

                {content && content.length > 0 && <div className="page-padding20"> <Pager
                    total={totalElements}
                    current={terminalPaging.page}
                    perPageCountList={[20, 50, 100]}
                    perPageCount={terminalPaging.size}
                    onPageChange={e => { this.props.getTerminalList({ ...terminalPaging, page: e }) }}
                    onPerPageChange={e => { this.props.getTerminalList({ ...terminalPaging, size: e, page: 0 }) }}
                />
                </div>}

                {
                    this.state.THSVisible &&
                    <TableHeaderSettingModal
                        visible={this.state.THSVisible}
                        handleVisible={() => {
                            this.props.getEseData({ dictDataType: "TERMINAL_SHOP" });
                            this.setState({ THSVisible: false });
                        }}
                        eseData={this.props.eseData.map(item => ({ text: item.name, value: item.dictId, id: item.dictId, titleShow: item.titleShow }))}
                        handleConfigTableTitle={this.props.handleConfigTableTitle}
                        dictDataType='TERMINAL_SHOP'
                    ></TableHeaderSettingModal>
                }

                {
                    this.state.configVisible && (
                        <Dialog title="门店修改限制" visible={this.state.configVisible} confirming={this.state.configLoading}
                            onOk={() => {
                                if (!this.state.gapDay) {
                                    notification.error('请填写间隔')
                                    return
                                }
                                this.setState({ configLoading: true })
                                $http({
                                    url: '/data/portal/business-config/v1',
                                    method: 'post',
                                    data: {
                                        code: 'shopUpdateIntervalTime',
                                        name: '门店修改间隔时间',
                                        content: this.state.gapDay
                                    }
                                })
                                .then(() => {
                                    this.setState({ configVisible: false })
                                })
                                .finally(() => {
                                    this.setState({ configLoading: false })
                                })
                            }}
                            onCancel={() => {
                                this.setState({ configVisible: false, gapDay: '' })
                            }}
                            style={{ width: 320, minWidth: 320 }}>
                            <div>
                                <span>每次修改间隔</span>
                                <Input
                                    style={{ width: 50, margin: '0 5px' }}
                                    value={this.state.gapDay}
                                    onChange={e => {
                                        this.setState({ gapDay: e.target.value })
                                    }}
                                    onBlur={e => {
                                        const value = e.target.value
                                        if (!isNaN(+value) && +value > 0) {
                                            this.setState({ gapDay: +(+value).toFixed(0) || '' })
                                        } else {
                                            this.setState({ gapDay: '' })
                                        }
                                    }}
                                />
                                <span>天，后可重新调整！</span>
                            </div>
                        </Dialog>
                    )
                }

                <DownLoadAnimate animateIcon=".export-button" animateShow={this.state.animateShow} />
            </React.Fragment>
        )
    }
}



